span {
    color: #fff;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.own-field-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px !important;
}

.own-field-description {
    font-size: 14px;
    margin-top: 6px;
}

.align-comment-section {
    display: flex;
    flex-direction: column;
    width: 50%;
}
