#footer-container {
  text-align: center;
}

#footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 18px;
}

#copyright,
#privacy-imprint-container {
  margin: 25px;
}

.no-linebreak {
  display: inline;
  text-decoration: none;
}

.no-decoration {
  text-decoration: none;
}

.divider {
  font-size: 24px;
  font-weight: lighter;
  opacity: 0.4;
  margin-top: -5px;
}

.margin-5 {
  margin: 5px;
}

.footer-seperator {
  margin: 13px auto 10px auto;
  background-color: white;
  height: 1px;
  opacity: 0.4;
}

@media only screen and (max-width: 959px) {
  .font-size-14 {
    font-size: 14px;
  }

  .divider {
    visibility: hidden;
  }
}

@media only screen and (max-width: 559px) {
  .font-size-10 {
    font-size: 14px;
  }

  .divider {
    visibility: hidden;
  }

}