#page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#contact-cis-logo {
    height: 120px;
    margin: 88px 0 50px 0;
}

#contact-header {
    margin: 0 0 50px 0;
}

/*#basic-data-container {*/
/*    margin: 0px 0px 0px 25px;*/
/*    flex: 1;*/
/*}*/

#invalid-token-area {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#to-getloginlink-button {
    margin-top: 100px;
}

#send-consent-button {
    margin-top: 50px;
}

#send-reject-button {
    margin-top: 50px;
    margin-left: 24px;
}

.disclaimer-text {
    max-width: 1630px;
    margin-right: 25px;
}

#privacy-email {
    font-weight: bold;
}

.link {
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.header {
    margin-top: 50px;
}

.contact-link {
    color: #fff;
    font-weight: bold;
}