#header-container {
  display: flex;
  justify-content: space-between;
}

#cis-logo-header {
  margin-top: 8px;
}

.no-textdecoration {
  text-decoration: none;
}

#cis-logo-container {
  display: flex;
  margin-left: -5px;
}

#logo-text {
  color: #ffffff;
  margin-top: 17px;
  padding-left: 10px;
}

#nav-container {
  display: flex;
}

#login-button {
  margin-top: 14px;
  margin-left: 40px;
}

.header-icon {
  margin-top: 23px;
  margin-left: 40px;
  fill: #ffffff;
  cursor: pointer;
}

.header-icon:hover #header-language-icon-path {
  fill: #c6d2db;
}

#helpmenu-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-right: 204px;
  height: 0;
}

#languagemenu-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-right: 140px;
  height: 0;
}
