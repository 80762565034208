#advantages-container {
  display: flex;
  text-align: center;
}

.margin-left-75 {
  margin-left: 75px;
}

.advantages-description {
  width: 175px;
  font-size: 12px;
}

@media only screen and (max-width: 959px) {
  .margin-left-75 {
    margin-left: 0;
  }

  #advantages-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }
}
