#error-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#error-image {
  margin-top: 10vh;
  margin-bottom: 5vh;
}

#error-headline {
  color: #fff;
  max-width: 80%;
  margin-bottom: 80px;
}