#header-help-menu {
  text-align: center;
  z-index: 1;
  max-width: 145px;
  min-height: 137px;
}

.header-help-menu-item {
  cursor: pointer;
  color: #4c5356;
  margin-left: 8px;
}

.header-help-menu-item:hover {
  color: #20607e;
}

#header-help-menu-divider {
  margin: 20px 0 20px 0;
}

.header-menu-item-container {
  display: flex;
}

.header-help-menu-icon {
  cursor: pointer;
  color: #4c5356;
}

.header-help-menu-icon:hover {
  color: #20607e;
}