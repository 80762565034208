.button-wrapper {
  margin: 0 0 0 0;
  margin-right: 15px;
}

.button-row {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.confirm-text {
  color: var(--groupui-vwag-color-petrol-800-primary);
  margin-top: 16px;
}