#loggedout-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loggedout-image {
  margin-top: 10vh;
  margin-bottom: 5vh;
}

@media only screen and (max-width: 600px) {
  #loggedout-image {
    width: 25%;
    height: 25%;
  }
}

#loggedout-headline {
  color: #fff;
  max-width: 80%;
}

#loggedout-buttons-container {
  margin-top: 3vh;
  margin-bottom: 150px;
}
