#header-language-menu {
  text-align: center;
  z-index: 1;
  max-width: 135px;
  min-height: 197px;
}

.header-language-menu-item {
  cursor: pointer;
  color: #4c5356;
}

.header-language-menu-item:hover {
  color: #20607e;
}

.header-language-menu-divider {
  margin: 20px 0 20px 0;
}