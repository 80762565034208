#faq-content-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

#faq-content-container2 {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 700px;
}

#faq-title-container {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 32px;
}

#faq-title {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
}

#logo-faq-container {
  display: flex;
}

.faq-accordion-container {
  width: 100%;
}

.faq-link {
  text-decoration: underline;
  font-weight: bold;
}