.flex-end {
  display: flex;
  justify-content: flex-end;
}

#cis-logo-headline {
  margin-top: 100px;
}

#cis-logo-body {
  width: 150px;
  margin-bottom: -25px;
}

#logistics-worldmap {
  max-width: 800px;
}

#landingpage-advantages-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
}

#learn-more-button-container {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

#mydata-button {
  margin-top: 25px;
}

.seperator {
  margin: 100px auto 50px auto;
  background-color: white;
  height: 1px;
  opacity: 0.4;
}

.headline-small {
  text-align: center;
  margin: 0 0 50px 0;
}

.features-headline-description-container {
  margin: 50px;
}

.features-description {
  width: 400px;
}

.features-left {
  margin: 100px auto 50px auto;
  display: flex;
  align-items: center;
}

.features-right {
  margin: 100px auto 50px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.features-center {
  text-align: center;
  margin: 100px auto 0 auto;
}

#devices-container {
  margin-bottom: 50px;
}

#devices-headline-description {
  margin-bottom: 50px;
}

#contact-container {
  text-align: center;
}

#slack-icon {
  margin: 50px 50px 25px 0;
}

#mail-icon {
  margin: 0 0 30px 0;
}

@media only screen and (max-width: 1619px) {
  #logistics-worldmap {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1279px) {
  #cis-logo-headline-container {
    text-align: center;
    justify-content: center;
  }

  #logistics-worldmap {
    visibility: hidden;
  }

  .hidden-l {
    display: none;
  }

  #landingpage-advantages-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  #only-europe {
    align-self: flex-end;
    margin: 50px 25px 25px 0;
  }

  #features-headline {
    margin-bottom: 0;
  }

  .features-description {
    width: 100%;
    margin: auto;
  }

  .features-left,
  .features-right {
    margin: 50px 0 0 0;
    align-self: center;
    text-align: center;
    flex-direction: column;
  }

  .features-center {
    margin: 0 0 0 0;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .feature-illustration {
    width: 75%;
  }

  #devices-headline-description {
    margin: 50px 0 0 0;
  }

  #contact-container {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 959px) {
  #cis-logo-headline {
    margin-top: 50px;
  }

  #learn-more-button-container {
    margin-top: 50px;
  }

  .features-left,
  .features-right,
  .features-center {
    margin: 30px 0 0 0;
  }

  .features-headline-description-container,
  #devices-headline-description {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 559px) {

  .features-left,
  .features-right,
  .features-center {
    margin: 0 0 0 0;
  }

  .features-headline-description-container,
  #devices-headline-description {
    margin-top: -25px;
  }
}

.landing-page-faq-link {
  text-decoration: underline;
  font-weight: bold;
}

.faq-link-container {
  display: flex;
  justify-content: center;
}

#faq-support-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

#faq-support-icon {
  margin-right: 4px;
}