#only-europe-getlogin {
  margin-top: 50px;
  margin-bottom: 150px;
}

#logo-headline-mail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 150px;
}

#email-container {
  margin-top: 25px;
  position: relative;
  max-width: 450px;
}

#email-description {
  margin-bottom: 15px;
}

#request-button {
  margin: 25px 0 0 0;
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 1279px) {
  #logo-headline-mail-container {
    align-items: center;
    margin-bottom: 50px;
  }

  #request-button {
    margin: 25px 0 0 0;
  }
}
