#page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#content {
    flex: 1;

    margin: 100px 25px 25px 25px;
    text-align: left;
    word-wrap: break-word;
}

#terms-of-use-cis-logo {
    height: 120px;
    margin: -12px 0px 50px 0px;
}